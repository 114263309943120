import React, { useState } from "react";
import { graphql, useStaticQuery, Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

import SecondaryLanding from "../../../../layouts/secondary-landing";
import HeroChevron from "../../../../components/hero/hero-chevron";
import Breadcrumb from "../../../../components/breadcrumb/breadcrumb";
import SEO from "../../../../components/seo/seo";
import Icon from "../../../../components/custom-widgets/icon";
import FaqAccordion from "../../../../components/faq/faq-accordion";

import calcPic from "../../../../images/calculator-pictogram.png";

import getHeroImgVariables from "../../../../helpers/getHeroImgVariables";

import "../../../personal-banking/home-loans/smart-start.bootstrap.scss";
import InfoCircleTooltip from "../../../../components/custom-widgets/info-circle-tooltip";
import incomeData from "../../../../data/income-data";

const SpanishSmartStartDownPaymentAssistance = () => {
  const [serviceState, setServiceState] = useState(null);
  const [county, setCounty] = useState(null);
  const [income, setIncome] = useState(null);

  function handleServiceStateChange(event) {
    setServiceState(event.target.value);
    setCounty(null);
  }

  function handleCountyChange(event) {
    setCounty(event.target.value);
  }

  function handleCalculate(event) {
    event.preventDefault();
    const result = incomeData[serviceState].filter((value) => value.county === county)[0].income;
    setIncome(result);
  }

  const pageData = useStaticQuery(graphql`
    {
      imgVariableXXL: file(
        relativePath: { eq: "hero/personal-banking/home-loans/smart-start/hero-smart-start-012523-XXL.jpg" }
      ) {
        ...heroChevronImageFragmentXXL
      }
      imgVariableXL: file(
        relativePath: { eq: "hero/personal-banking/home-loans/smart-start/hero-smart-start-012523-XL.jpg" }
      ) {
        ...heroChevronImageFragmentXL
      }
      imgVariableLG: file(
        relativePath: { eq: "hero/personal-banking/home-loans/smart-start/hero-smart-start-012523-LG.jpg" }
      ) {
        ...heroChevronImageFragmentLG
      }
      imgVariableMD: file(
        relativePath: { eq: "hero/personal-banking/home-loans/smart-start/hero-smart-start-012523-MD.jpg" }
      ) {
        ...heroChevronImageFragmentMD
      }
      imgVariableSM: file(
        relativePath: { eq: "hero/personal-banking/home-loans/smart-start/hero-smart-start-012523-SM.jpg" }
      ) {
        ...heroChevronImageFragmentSM
      }
      imgVariableXS: file(
        relativePath: { eq: "hero/personal-banking/home-loans/smart-start/hero-smart-start-012523-XS.jpg" }
      ) {
        ...heroChevronImageFragmentXS
      }
      imgVariableXXS: file(
        relativePath: { eq: "hero/personal-banking/home-loans/smart-start/hero-smart-start-012523-XXS.jpg" }
      ) {
        ...heroChevronImageFragmentXXS
      }
    }
  `);

  const heroChevronData = {
    id: "spanish-smart-start-hero",
    ...getHeroImgVariables(pageData),
    altText: "Familia jugando con el marco de una fotografía en su casa nueva.",
    heroBody: {
      components: [
        {
          id: 1,
          heading: {
            class: "text-white",
            text: "No Hay Lugar Como el Hogar"
          }
        },
        {
          id: 2,
          subheading: {
            class: "text-white",
            text: "Con Smart Start comprar una casa es posible."
          }
        }
      ]
    }
  };

  const breadcrumbData = [
    {
      id: 1,
      url: "/es",
      title: "Banca Personal"
    },
    {
      id: 2,
      url: "/es/banca-personal/prestamos-hipotecarios",
      title: "Préstamos Hipotecarios"
    },
    {
      id: 3,
      active: true,
      title: "Hipoteca Smart Start"
    }
  ];

  const title = "Asistencia para compradores de vivienda por primera vez con Smart Start",
    description =
      "Explore el programa de asistencia para compradores de vivienda por primera vez Smart Start en WaFd Bank. Disfrute de una contribución mínima de tan solo el 1%, sin PMI y de hasta un 2% de condonación de préstamos.";
  const SEOData = {
    title: title,
    meta: [
      {
        name: "application-name",
        content: "WaFd Bank public website"
      },
      {
        name: "title",
        content: title
      },
      {
        name: "description",
        content: description
      },
      {
        property: "og:title",
        content: title
      },
      {
        property: "og:description",
        content: description
      },
      {
        property: "og:url",
        content: "https://www.wafdbank.com/es/banca-personal/prestamos-hipotecarios/hipoteca-smart-start"
      },
      {
        property: "og:type",
        content: "website"
      },
      {
        property: "og:site_name",
        content: "wafdbank"
      },
      {
        property: "og:image",
        content: "https://www.wafdbank.com/images/adsearch/og-smart-start-012523.jpg"
      }
    ]
  };

  const smartStartBenefitsData = [
    {
      text: `<div class="h3-font-size font-weight-semi-bold">Desde 1%</div> de contribución mínima requerida*`
    },
    {
      text: `Smart Start Boost disponible para apoyarte con el enganche**`
    },
    {
      text: `No es requerido obtener aseguranza privada para la vivienda*`
    }
  ];

  const WhiteCheckmark = () => (
    <span className="fa-li">
      <Icon className="mr-2 text-white" name="check-circle" lib="fas" />
    </span>
  );

  const faqAccordionData = {
    id: "es-smart-start-faq-accordion",
    title: "Glosario",
    faqCategoryNames: ["Spanish Smart Start"]
  };

  return (
    <SecondaryLanding>
      <SEO {...SEOData} />
      <HeroChevron {...heroChevronData} />
      <Breadcrumb data={breadcrumbData} />

      <section className="bg-light">
        <div className="container">
          <div className="row">
            <div className="col-md-6 mb-3">
              <StaticImage
                src="../../../../images/smart-start-woman-moving.jpg"
                alt="Happy African-American woman moving into a new home"
                placeholder="blurred"
                loading="eager"
                quality="100"
              />
            </div>
            <div className="col-md-6">
              <h1 className="h3-font-size">
                Comprar una casa es más que una emoción; una casa asequible es puerta de entrada al éxito financiero a
                corto y largo plazo.
              </h1>
              <p>
                A largo plazo tendrá liquidez que podrá usar en el futuro, a corto plazo disfrutará de potenciales
                deducciones de impuestos y ahorrará en vez de pagar renta. Además, nuestros amigables banqueros están
                disponibles para cualquier pregunta que pueda tener, ya sea grande o pequeña.
              </p>
              <p>
                ¿Estás preparado para Smart Start? Acércate a un Asesor Hipotecario de WaFd para descubrir cómo te
                podemos ayudar a obtener tu primer hogar.
              </p>
              <p>
                <Link
                  id="smart-start-find-loan-officer-cta"
                  to="/es/banca-personal/contacte-a-un-asesor-hipotecario"
                  className="btn btn-primary"
                >
                  Encuentra un Prestamista Local
                </Link>
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* Smart Start Benefit Section >= sm */}
      <section className="d-none d-lg-block py-0" id="smart-start-benefits-section">
        <div className="container">
          <div className="row justify-content-center">
            {smartStartBenefitsData.map((benefit, index) => (
              <div
                key={index}
                className="d-flex justify-content-center bg-success text-white smart-start-benefit-circle mx-2"
              >
                <div className="align-items-center d-flex justify-content-center flex-column px-4 text-center smart-start-benefit-circle-dimensions">
                  <div className="font-weight-bold" dangerouslySetInnerHTML={{ __html: benefit.text }} />
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Smart Start Benefit Section < sm */}
      <section className="bg-success d-block d-lg-none text-white">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <ul className="fa-ul mb-0">
                <li>
                  <WhiteCheckmark />
                  <span className="h4-font-size font-weight-semi-bold">Solo 3%</span>
                  <span className="font-weight-bold"> de enganche es requerido</span>
                </li>
                <li>
                  <WhiteCheckmark />
                  <span className="font-weight-bold" id="ss-program-benefits-2-cont">
                    Smart Start Boost disponible para apoyarte con el enganche
                  </span>
                </li>
              </ul>
            </div>
            <div className="col-md-6">
              <ul className="fa-ul mb-0">
                <li>
                  <WhiteCheckmark />
                  <span className="font-weight-bold">No es requerido obtener aseguranza privada para la vivienda*</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <section className="container mt-4">
        <div className="row">
          <div className="col-md-6">
            <h4>
              Usa Smart Start Boost para pagar tu enganche, costos para cerrar el trato, o reducir la tasa de interés de
              tus mensualidades.
            </h4>
            <h4 className="text-success font-weight-semi-bold">Información para calificar:</h4>
            <ul>
              <li>Puntaje de crédito debe ser mayor a 640.</li>
              <li>Se aplican límites de ingreso de vivienda.</li>
              <li>Debe ser usado para la compra de una residencia primaria.</li>
              <li>La asistencia de Smart Start Boost para su enganche es un segundo gravamen seguro.</li>
              <li>Fondos de regalo, contribución del vendedor o cualquier otra tarifa de asistencia es aceptada.</li>
            </ul>
            <div className="mb-4">
              <Link
                id="smart-start-find-loan-officer-cta-2"
                to="/es/banca-personal/contacte-a-un-asesor-hipotecario"
                className="btn btn-primary"
              >
                Encuentra un Oficial de Préstamo
              </Link>
            </div>
            <p className="text-sm text-muted">
              Todos los préstamos están sujetos a aprobación de crédito, términos y condiciones adicionales pueden
              aplicar.
              <br />
              *Los prestatarios deben contribuir el mínimo de 1% del total de la compra con fondos del mismo
              prestatario.
              <br />
              ** El enganche mínimo para Smart Start Mortage es del 3% del precio de compra. Smart Start Boost solo está
              disponible con la Smart Start Mortgage. Los fondos de Smart Start Boost pueden ser utilizados para reducir
              el enganche del 3% a 1% del precio de compra, reducir tus gastos para cerrar la oferta, o para reducir tu
              tasa de interés. Aplican restricciones. Acércate a tu prestamista para más detalles y requisitos
              adicionales.
            </p>
          </div>
          <div
            id="do-you-qualify-calculator"
            className="col-md-6 px-4 bg-light border-radius-top-12 border-radius-bottom-12 mt-5 mt-md-3 mt-lg-1"
            style={{ height: "fit-content" }}
          >
            <div className="text-center" style={{ marginTop: "-55px" }}>
              <img src={calcPic} alt="calculator-pictogram" />
            </div>
            <div className="h3 text-success text-center pb-3 px-sm-3 pt-2 border-bottom">
              ¿Califica para nuestra Hipoteca Smart Start?
            </div>

            <form onSubmit={handleCalculate} className="mt-2">
              <strong>¿Dónde se encuentra la propiedad que desea comprar?</strong>
              <div className="form-row justify-content-between mt-3">
                <div className="col-md-6 col-lg-5">
                  <label htmlFor="service-state" className="mr-2">
                    Estado
                  </label>
                  <InfoCircleTooltip
                    id={"personal-banking-tooltip"}
                    trigger={["hover", "focus"]}
                    content={
                      "Para abrir una cuenta con nosotros, debe residir en uno de los ocho estados del oeste en los que operamos: Arizona, California, Idaho, Nevada, Nuevo México, Oregón, Texas, Utah o Washington."
                    }
                    icon={{
                      title: "",
                      class: "text-gray-60",
                      name: "info-circle",
                      lib: "far"
                    }}
                  />
                  <select
                    id="service-state"
                    className="form-control border-radius-6 custom-select-chevron"
                    value={serviceState}
                    onChange={handleServiceStateChange}
                  >
                    {<option value=""></option>}
                    {Object.keys(incomeData).map((option) => (
                      <option value={option}>{option === "New Mexico" ? "Nuevo México" : option}</option>
                    ))}
                  </select>
                </div>
                <div className="mt-3 mt-md-0 col-md-6 col-lg-5">
                  <label htmlFor="county" className="mr-2">
                    Condado
                  </label>
                  <InfoCircleTooltip
                    id={"personal-banking-tooltip"}
                    trigger={["hover", "focus"]}
                    content={"Actualmente solo ofrecemos este programa en condados selectos."}
                    icon={{
                      title: "",
                      class: "text-gray-60",
                      name: "info-circle",
                      lib: "far"
                    }}
                  />
                  <select
                    id="county"
                    className="form-control border-radius-6 custom-select-chevron"
                    value={county}
                    onChange={handleCountyChange}
                  >
                    {<option value=""></option>}
                    {incomeData[serviceState]?.map((option) => (
                      <option value={option.county} key={option.county}>
                        {option.county}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="form-row justify-content-md-end">
                <button
                  id="calculate-btn"
                  className="btn btn-primary no-min-width my-4"
                  type="submit"
                  disabled={!serviceState || !county}
                >
                  Calcular
                </button>
              </div>
            </form>

            {income ? (
              <div
                className="bg-green-60 text-white py-3 px-4 border-radius-bottom-12"
                style={{ marginLeft: "-23px", marginRight: "-23px" }}
              >
                El ingreso de su hogar no puede exceder{" "}
                <span className="lead" id="income">
                  {income}
                </span>{" "}
                para participar en nuestra Hipoteca Smart Start según la ubicación general del condado. La ubicación
                exacta de la casa puede afectar los límites de ingresos.
                <div className="mt-3">
                  <strong>¿Tus ingresos son mayores?</strong>{" "}
                  <Link
                    to="/es/banca-personal/prestamos-hipotecarios"
                    className="text-white text-decoration-none text-nowrap"
                    id="loans-programs-link"
                  >
                    Ver programas de préstamo adicionales
                    <Icon name="arrow-right" class="ml-1" />
                  </Link>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </section>

      <FaqAccordion {...faqAccordionData} />
    </SecondaryLanding>
  );
};

export default SpanishSmartStartDownPaymentAssistance;
